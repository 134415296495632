<script setup>
  import Page from '@/components/Page.vue'
  import SimpleBreadCrumb from '@/components/SimpleBreadCrumb.vue'
  import { useRoute } from 'vue-router'
  import { onMounted, reactive } from 'vue'
  import php from '@/php/index.js'
  import TagBadge from '@/views/blog/components/TagBadge.vue'
  import VerticalCard from '@/views/blog/components/VerticalCard.vue'
  import PageLoader from "@/views/blog/components/PageLoader.vue";
  const data = reactive({
    blogSlug: '',
    popularTags: [],
    recentBlogs: [],
    blog: {},
    loading: true
  })

  const getBlog = (slug) => {
    php
      .get(`services/public/blog-detail-content/${slug}`)
      .then((response) => {
        data.blog = response.data.data.blog
        data.recentBlogs = response.data.data.recentBlogs
        data.popularTags = response.data.data.popularTags
        data.loading = false;
      })
      .catch((error) => {
        console.log('error:', error)
      })
  }

  onMounted(() => {
    data.blogSlug = useRoute().params.slug || ''
    getBlog(data.blogSlug)
  })
</script>

<template>
  <PageLoader v-if="data.loading"></PageLoader>
  <Page  v-else class="page-box content">
    <simple-bread-crumb
    :firstRoute="{ name: 'Blog', path: '/blogs' }"
    :second-route="{ name: data.blog.name, path: `/blog/${data.blog.slug}` }"
    ></simple-bread-crumb>
    <el-row :gutter="16">
      <el-col
          :lg="{ span: 18, }"
          :md="{ span: 18 }"
          :sm="{ span: 24 }"
          :xs="{ span: 24 }">
        <div class="grid-content ep-bg-purple content-container">
          <img :src="data.blog.banner" alt="blog image">
          <div class="tags">
          <TagBadge
              v-for="(tag, index) in data.blog.tags"
              :key="tag.slug"
              :slug="tag.slug"
              :name="tag.name"
              :index="index+1"
              :show-badge="true"
          />
          </div>
          <h1 class="pt-8 pb-8 heading">{{ data.blog.title }}</h1>
          <div class="pt-16 pb-16 " v-html="data.blog.content"></div>
        </div>

      </el-col>
      <el-col :lg="{ span: 6 }"
              :md="{ span: 6 }"
              :sm="{ span: 24 }"
              :xs="{ span: 24 }">

        <h2 class="heading">Recent Blog</h2>
        <div class="grid-content ep-bg-purple" >
          <vertical-card
              v-for="blog in data.recentBlogs"
              :key="blog.slug"
              :image="blog.thumbnail"
              :title="blog.title"
              :description="blog.short_description"
              :read-time="blog.readTime"
              :slug="blog.slug" :tags="[]">
          </vertical-card>
        </div>
        <hr class="custom-hr"/>
        <h2 class="heading">Popular Tags</h2>
        <div class="tags">
          <TagBadge
              v-for="(tag, index) in data.popularTags"
              :key="tag.slug"
              :slug="tag.slug"
              :name="tag.name"
              :index="index+1"
          />
        </div>
      </el-col>
    </el-row>
  </Page>

</template>

<style  lang="less">
img{
  width: 100%;
  border-radius: 4px;
}
.tags {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 16px 0 8px 0;
}
.pt-8{
  padding-top: 8px;
}
.pb-8{
  padding-bottom: 8px;
}
.pt-16{
  padding-top: 16px;
}
.pb-16{
  padding-bottom: 16px;
}
.content-container {
  word-break: break-word;
  ol li {
    list-style-type: disc;
  }
  ul li {
    list-style-type: bullet;
  }
  em {
    font-style: italic;
  }
}
.heading{
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  word-break: break-all;
}
.custom-hr {
  border: none;
  height: 2px;
  background: #DCDFE6;
}
</style>